<script setup lang="ts">
import { mdiMenuRight } from "@mdi/js";

const { localeProperties } = useI18n();
const homeLinkObject = computed(() => {
  return {
    link_type: "Document",
    type: "home_page",
    id: "home_page",
    lang: localeProperties.value.language,
  };
});
</script>
<template>
  <div>
    <MHeader is-transparent />
    <main
      class="-mt-[93px] flex h-dvh flex-col items-center justify-center pt-[93px]"
    >
      <p class="text-[min(20vw,120px)]">404</p>
      <p class="text-[min(5vw,30px)] text-opacity-50">
        {{ $t("ErrorPage.NotFound") }}
      </p>
      <BButton class="mt-10" :link-object="homeLinkObject">
        <BIcon :path="mdiMenuRight" /> {{ $t("ErrorPage.GoHome") }}
      </BButton>
    </main>
  </div>
</template>
