<script setup lang="ts">
import { mdiMenu, mdiWindowClose } from "@mdi/js";
import { vOnClickOutside } from "@vueuse/components";
import { useMouse } from "@vueuse/core";

import navbar from "@/scripts/prismic/navbar.json";

const { localeProperties } = useI18n();
const navbarData = computed(() => navbar[localeProperties.value.language]);
const mobileMenus = computed(() => navbarData.value.menu);

const isPageLoaded = ref(false);
const { sourceType } = useMouse();
const { asLink } = usePrismic();

onNuxtReady(() => {
  isPageLoaded.value = true;
});

onUnmounted(() => {
  document.body.classList.remove("overflow-y-hidden");
});

const isOpen = ref(false);
const isAnimating = ref(false);
watch(isOpen, (v) => {
  if (v) {
    document.body.classList.add("overflow-y-hidden");
  } else {
    document.body.classList.remove("overflow-y-hidden");
  }
});

const onMouseOverToOpen = () => {
  if (sourceType.value === "touch") return;
  if (isAnimating.value) return;

  isOpen.value = true;
};

const onMouseOverToClose = async () => {
  if (sourceType.value === "touch") return;
  if (isAnimating.value) return;

  isOpen.value = false;
};

const onClick = () => {
  isOpen.value = true;
};
</script>

<template>
  <div class="h-[40px] w-[40px] text-black">
    <button
      class="relative z-10 flex h-[40px] w-[40px] scale-0 items-center justify-center rounded-full p-1 opacity-80 transition-transform duration-[0.5s]"
      :class="{ 'scale-100 hover:!opacity-100': isPageLoaded }"
      @mouseover="onMouseOverToOpen"
      @click="onClick"
      aria-label="menu"
    >
      <BIcon :path="mdiMenu" />
    </button>

    <Transition
      name="fade"
      @before-enter="() => (isAnimating = true)"
      @after-enter="() => (isAnimating = false)"
      @before-leave="() => (isAnimating = true)"
      @after-leave="() => (isAnimating = false)"
    >
      <div
        v-if="isOpen"
        class="fixed left-0 top-0 z-[1000] h-dvh w-screen bg-black bg-opacity-60"
        @mouseover="onMouseOverToClose"
      >
        <div
          v-on-click-outside="() => (isOpen = false)"
          @mouseover="(e) => e.stopPropagation()"
          class="quarter-menu transition-position absolute right-0 top-0 z-[1200] h-[350px] w-[220px] origin-top-right bg-white p-6 ease-out -sm:p-4 -sm:py-2"
        >
          <button
            class="relative z-[1300] flex h-[40px] w-[40px] items-center justify-center rounded-full p-1 text-black"
            @click="isOpen = false"
          >
            <BIcon :path="mdiWindowClose" />
          </button>

          <ul class="flex flex-col items-end gap-1">
            <li v-for="(menu, idx) in mobileMenus" :key="idx">
              <NuxtLink
                :to="asLink(menu.link)"
                class="block px-2 py-2 text-xl uppercase tracking-[0.3rem] text-black opacity-90 hover:opacity-100"
                @click="isOpen = false"
              >
                {{ menu.link.text }}
              </NuxtLink>
            </li>
            <li class="flex p-2 px-3">
              <NuxtLink
                :to="
                  asLink({
                    link_type: 'Document',
                    type: 'home_page',
                    lang: localeProperties.language,
                    id: 'home_page',
                  })
                "
                class="block h-[16px] w-[16px] rounded-full bg-gray-300 hover:bg-black"
                @click="isOpen = false"
                aria-label="Home"
              />
            </li>
          </ul>
        </div>
      </div>
    </Transition>
  </div>
</template>

<style scoped lang="scss">
.quarter-menu {
  border-radius: 0 0 0 300px;
}

.fade-enter-active,
.fade-leave-active {
  transition: all 0.3s;

  & > .quarter-menu {
    transition: all 0.3s;
  }
}

.fade-enter-to,
.fade-leave-from {
  opacity: 1;

  & > .quarter-menu {
    transform: scale(100%);
  }
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;

  & > .quarter-menu {
    transform: scale(0);
  }
}
</style>
