<script setup lang="ts">
const { initialize } = useGtag();

onNuxtReady(() => {
  initialize();
});
</script>
<template>
  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>
</template>
