import revive_payload_client_Yk8hxB1xWw from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.9_eslint@9.13.0_jiti@2.3.3__ioredis@5.4.1__j3h7pxg66krwy67wot72uf43ha/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_57M0HZ9vpb from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.9_eslint@9.13.0_jiti@2.3.3__ioredis@5.4.1__j3h7pxg66krwy67wot72uf43ha/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_aVwlZxN0LE from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.9_eslint@9.13.0_jiti@2.3.3__ioredis@5.4.1__j3h7pxg66krwy67wot72uf43ha/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_HZZhDFRz65 from "/opt/build/repo/node_modules/.pnpm/nuxt-site-config@2.2.18_magicast@0.3.5_rollup@4.24.0_vite@5.4.10_@types+node@22.7.9_sass-embe_cxzrfhztd4tetpgu7565l672vu/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_CvkUC8WSrQ from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.9_eslint@9.13.0_jiti@2.3.3__ioredis@5.4.1__j3h7pxg66krwy67wot72uf43ha/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_9wLiR6PDUQ from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.9_eslint@9.13.0_jiti@2.3.3__ioredis@5.4.1__j3h7pxg66krwy67wot72uf43ha/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_XjjE4cchGJ from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.9_eslint@9.13.0_jiti@2.3.3__ioredis@5.4.1__j3h7pxg66krwy67wot72uf43ha/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_blAlH8Dvza from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.9_eslint@9.13.0_jiti@2.3.3__ioredis@5.4.1__j3h7pxg66krwy67wot72uf43ha/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_EzIQGIe80m from "/opt/build/repo/node_modules/.pnpm/@pinia+nuxt@0.5.5_magicast@0.3.5_rollup@4.24.0_typescript@5.6.3_vue@3.5.12_typescript@5.6.3_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/opt/build/repo/.nuxt/components.plugin.mjs";
import prefetch_client_KzeWyhXSS4 from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.9_eslint@9.13.0_jiti@2.3.3__ioredis@5.4.1__j3h7pxg66krwy67wot72uf43ha/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import primevue_plugin_egKpok8Auk from "/opt/build/repo/.nuxt/primevue-plugin.mjs";
import plugin_client_j9qSt1AwcJ from "/opt/build/repo/node_modules/.pnpm/@primevue+nuxt-module@4.2.1_@babel+parser@7.25.9_magicast@0.3.5_rollup@4.24.0_vue@3.5.12_typescript@5.6.3_/node_modules/@primevue/nuxt-module/dist/runtime/plugin.client.js";
import switch_locale_path_ssr_jVLfBSm2Og from "/opt/build/repo/node_modules/.pnpm/@nuxtjs+i18n@8.5.5_magicast@0.3.5_rollup@4.24.0_vue@3.5.12_typescript@5.6.3_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_ipqiXPJvN0 from "/opt/build/repo/node_modules/.pnpm/@nuxtjs+i18n@8.5.5_magicast@0.3.5_rollup@4.24.0_vue@3.5.12_typescript@5.6.3_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import plugin_client_aLvwq1Z67y from "/opt/build/repo/node_modules/.pnpm/@nuxtjs+prismic@3.4.3_magicast@0.3.5_rollup@4.24.0_vite@5.4.10_@types+node@22.7.9_sass-embedd_ziso3wafdfuri567kclq6fmfe4/node_modules/@nuxtjs/prismic/dist/runtime/plugin.client.js";
import plugin_ayVPdmd0q0 from "/opt/build/repo/node_modules/.pnpm/@nuxtjs+prismic@3.4.3_magicast@0.3.5_rollup@4.24.0_vite@5.4.10_@types+node@22.7.9_sass-embedd_ziso3wafdfuri567kclq6fmfe4/node_modules/@nuxtjs/prismic/dist/runtime/plugin.js";
export default [
  revive_payload_client_Yk8hxB1xWw,
  unhead_57M0HZ9vpb,
  router_aVwlZxN0LE,
  _0_siteConfig_HZZhDFRz65,
  payload_client_CvkUC8WSrQ,
  navigation_repaint_client_9wLiR6PDUQ,
  check_outdated_build_client_XjjE4cchGJ,
  chunk_reload_client_blAlH8Dvza,
  plugin_vue3_EzIQGIe80m,
  components_plugin_KR1HBZs4kY,
  prefetch_client_KzeWyhXSS4,
  primevue_plugin_egKpok8Auk,
  plugin_client_j9qSt1AwcJ,
  switch_locale_path_ssr_jVLfBSm2Og,
  i18n_ipqiXPJvN0,
  plugin_client_aLvwq1Z67y,
  plugin_ayVPdmd0q0
]