import { defineNuxtPlugin } from '#app/nuxt'
import { LazyBButton, LazyBIcon, LazyBInputText, LazyBSelect, LazyBimHero, LazyLanguageSelector, LazyMHeader, LazyMobileNav, LazyPImage, LazyPLink, LazyPageAbout, LazyPageBim, LazyPageBimHub, LazyPageContact, LazyPageHome, LazyPageProject, LazyPageProjectHub, LazyPageScrollProgressBar, LazyPill, LazyProjectHero, LazyRichText, LazySectionTitle } from '#components'
const lazyGlobalComponents = [
  ["BButton", LazyBButton],
["BIcon", LazyBIcon],
["BInputText", LazyBInputText],
["BSelect", LazyBSelect],
["BimHero", LazyBimHero],
["LanguageSelector", LazyLanguageSelector],
["MHeader", LazyMHeader],
["MobileNav", LazyMobileNav],
["PImage", LazyPImage],
["PLink", LazyPLink],
["PageAbout", LazyPageAbout],
["PageBim", LazyPageBim],
["PageBimHub", LazyPageBimHub],
["PageContact", LazyPageContact],
["PageHome", LazyPageHome],
["PageProject", LazyPageProject],
["PageProjectHub", LazyPageProjectHub],
["PageScrollProgressBar", LazyPageScrollProgressBar],
["Pill", LazyPill],
["ProjectHero", LazyProjectHero],
["RichText", LazyRichText],
["SectionTitle", LazySectionTitle],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
